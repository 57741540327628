<template>
  <div>
    <default-count-card 
      :value="data" 
      :title="item_title" 
      :title_class="title_class" 
      :icon="item_icon"
      :icon_color="icon_color"
      style="height: 100%"
      not_rounded
    >
      <template v-slot:extra>

      </template>
    </default-count-card>
  </div>
</template>

<script>
  export default {
    name: 'CountStatusesSettings',
    components: {
      DefaultCountCard: () => import ('../default_counter_card/DefaultCountCard.vue'),
    },
    props: {
      data: {
        type: Number,
      },
      item_title: {
        type: String,
      },
      item_icon: {
        type: String
      }
    },
    data() {
      return {
        title: 'Suspensos',
        title_class: 'primary--text overline text-center',
        icon: 'mdi-alert-circle-outline',
        icon_color: 'primary'
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>